<template>
  <b-container>
    <b-modal
      id="modal-edit"
      size="xl"
      centered
      title="Data Penilaian Karyawan oleh Mitra"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-row>
        <b-col>
          <b-card>
            <b-card-body>
              <h2>PENILAIAN KINERJA & KONFIRMASI PERSETUJUAN</h2>
              <p>
                Berikut Formulir Penilaian Kinerja dan konfirmasi persetujuan
                Perjanjian Kerja Waktu Tertentu (PKWT) Pegawai PT. Griya Pondok
                Binasejati
              </p>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card>
            <b-card-body>
              <b-form>
                <b-form-group label="Email" label-cols-md="4">
                  <b-form-input
                    disabled
                    type="text"
                    v-model="data.email"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Nama Penilai" label-cols-md="4">
                  <b-form-input
                    disabled
                    type="text"
                    v-model="data.namaPenilai"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Jabatan Penilai" label-cols-md="4">
                  <b-form-input
                    disabled
                    type="text"
                    v-model="data.jabatanPenilai"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Tempat Tugas" label-cols-md="4">
                  <b-form-input
                    disabled
                    type="text"
                    v-model="data.tempatTugas"
                  ></b-form-input>
                </b-form-group>
                <b-form-group label="Tanggal Penilaian" label-cols-md="4">
                  <date-picker
                    disabled
                    style="width: 100%"
                    v-model="data.tanggalPenilaian"
                    :placeholder="
                      moment(data.tanggalPenilaian).format('DD/MM/YYYY')
                    "
                  ></date-picker>
                </b-form-group>
                <b-form-group label="Nama Karyawan" label-cols-md="4">
                  <b-form-input
                    disabled
                    type="text"
                    v-model="data.namaKaryawan"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Tugas Kerja Karyawan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    disabled
                    v-model="data.namaPosisi"
                    :options="options"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card>
            <b-card-header class="bg-info">
              <h4>Penilaian Aspek Kepribadian</h4></b-card-header
            >
            <b-card-body>
              <b-form>
                <b-form-group
                  label="Kejujuran & Integritas dalam melakukan Pekerjaan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    disabled
                    v-model="data.penilaian1"
                    :options="options1"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Penampilan, etika & kelengkapan atribut seragam"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    disabled
                    v-model="data.penilaian2"
                    :options="options2"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Tanggung Jawab & disiplin dalam melakukan pekerjaan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    disabled
                    v-model="data.penilaian3"
                    :options="options3"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Kepatuhan dalam melakukan pekerjaan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    disabled
                    v-model="data.penilaian4"
                    :options="options4"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Sikap & motivasi dalam melakukan pekerjaan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    disabled
                    v-model="data.penilaian5"
                    :options="options5"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
            </b-card-body>
          </b-card>

          <b-card>
            <b-card-header class="bg-info">
              <h4>Penilaian Aspek Komunikasi</h4></b-card-header
            >
            <b-card-body>
              <b-form>
                <b-form-group
                  label="kerjasama dalam melakukan pekerjaan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    disabled
                    v-model="data.penilaian6"
                    :options="options6"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Koordinasi & berkomunikasi yang efektif"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    disabled
                    v-model="data.penilaian7"
                    :options="options7"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Kemampuan untuk berinteraksi & mengungkapkan ide"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    disabled
                    v-model="data.penilaian9"
                    :options="options8"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Kemampuan dalam menerima instruksi yang diberikan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    disabled
                    v-model="data.penilaian9"
                    :options="options9"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Kemampuan komunikasi yang baik untuk melayani pengguna jasa maupun rekan kerja"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    disabled
                    v-model="data.penilaian10"
                    :options="options10"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
            </b-card-body>
          </b-card>
          <b-card>
            <b-card-header class="bg-info">
              <h4>Penilaian Teknis Pekerjaan</h4></b-card-header
            >
            <b-card-body>
              <b-form>
                <b-form-group
                  label="Efektifitas & efisien si dalam melakukan pekerjaan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    disabled
                    v-model="data.penilaian11"
                    :options="options11"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Ketelitian dalam melakukan pekerjaan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    disabled
                    v-model="data.penilaian12"
                    :options="options12"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Kemampuan mencapai target Standard Operation Procedure (SOP) Perusahaan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    disabled
                    v-model="data.penilaian13"
                    :options="options13"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Inisiatif dalam menyelesaikan persoalan pekerjaan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    disabled
                    v-model="data.penilaian14"
                    :options="options14"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Antusias & sikap terhadap Pekerjaan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    disabled
                    v-model="data.penilaian15"
                    :options="options15"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
            </b-card-body>
          </b-card>
          <b-card>
            <b-card-header class="bg-info">
              <h4>Penilaian Aspek Produktivitas</h4></b-card-header
            >
            <b-card-body>
              <b-form>
                <b-form-group
                  label="Kemmapuan dalam melakukan pekerjaan sendiri"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    disabled
                    v-model="data.penilaian16"
                    :options="options16"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Kualitas, kerapian & keterampilan dalam melakukan pekerjaan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    disabled
                    v-model="data.penilaian17"
                    :options="options17"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Menunjukan minat meningkatkan pengetahuan yang baik untuk melakukan pekerjaan rutin / ekstra"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    disabled
                    v-model="data.penilaian18"
                    :options="options18"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Bertanggung jawab atas sarana & prasarana perlengkapan kerja yang dipercayakan"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    disabled
                    v-model="data.penilaian19"
                    :options="options19"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Kedisiplinan dalam kehadiran kerja serta disiplin dalam penggunaan waktu"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    disabled
                    v-model="data.penilaian20"
                    :options="options20"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
            </b-card-body>
          </b-card>
          <b-card>
            <b-card-header class="bg-info">
              <h4>Catatan Tentang Karyawan</h4></b-card-header
            >
            <b-card-body>
              <b-form>
                <b-form-group
                  label="Catatan dapat diisi seputar rencana usulan promosi/mutasi, prestasi karyawan, catatan khusus serta kritik"
                  label-cols-md="4"
                >
                  <b-form-input
                    disabled
                    type="text"
                    v-model="data.catatanKhusus"
                  ></b-form-input>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Persetujuan Penandatanganan PKWT"
                  label-cols-md="4"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-radio-group
                    disabled
                    v-model="data.persetujuanPenandatanganan"
                    :options="optionsSetuju"
                    :aria-describedby="ariaDescribedby"
                    name="radio-stacked"
                    stacked
                    class="pl-2"
                  ></b-form-radio-group>
                </b-form-group>
              </b-form>
              <b-form>
                <b-form-group
                  label="Apabila setuju, berapa jangka waktu kerja yang diinginkan ?
"
                  label-cols-md="4"
                >
                  <b-form-input
                    disabled
                    type="number"
                    v-model="data.jangkaWaktuKerja"
                  ></b-form-input>
                </b-form-group>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <!-- <b-card-footer>
        <b-row>
          <b-col>
            <center>
              <b-button variant="danger" @click="reset()"
                >Kosongkan Form</b-button
              >
            </center>
          </b-col>
          <b-col>
            <center>
              <b-button variant="success" @click="register"
                >Kirim Penilaian</b-button
              >
            </center>
          </b-col>
        </b-row>
      </b-card-footer> -->
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-edit')">
          Tutup
        </b-button>
        <b-button variant="primary" :disabled="busy" @click="simpan()">
          {{ button }}
        </b-button>
      </template></b-modal
    >

    <generator :data="data" :gen="gen" :url="url" :name="name" />
  </b-container>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend.js";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import "moment/locale/id";
import generator from "@/component/DOCX/docx_generator.vue";
export default {
  components: {
    DatePicker,
    generator,
  },

  props: ["data"],
  data() {
    return {
      moment,
      ipBackend,
      button: "Cetak Penilaian",
      busy: false,
      optionsSetuju: [
        { text: "Setuju", value: true },
        { text: "Tidak Setuju", value: false },
      ],
      options1: [
        { text: "Dapat dipercaya (3 Poin)", value: 3 },
        { text: "Memerlukan Pengawasan (2 Poin)", value: 2 },
        { text: "Sangat Memerlukan Pengawasan (1 Poin)", value: 1 },
      ],
      options2: [
        { text: "Sangat memuaskan (3 Poin)", value: 3 },
        { text: "Memuaskan (2 Poin)", value: 2 },
        { text: "Tidak memuaskan (1 Poin)", value: 1 },
      ],
      options3: [
        {
          text: "Melakukan pekerjaan dengan maksimal dan berusahan meningkatkan Performa (3 Poin)",
          value: 3,
        },
        { text: "Melakukan pekerjaan secara wajar (2 Poin)", value: 2 },
        {
          text: "Melakukan pekerjaan dengan tidak sungguh-sungguh (1 Poin)",
          value: 1,
        },
      ],
      options4: [
        { text: "Dapat dipercaya (3 Poin)", value: 3 },
        { text: "Memerlukan Pengawasan (2 Poin)", value: 2 },
        { text: "Sangat Memerlukan Pengawasan (1 Poin)", value: 1 },
      ],
      options5: [
        { text: "Antusias dalam melakukan pekerjaan (3 Poin)", value: 3 },
        { text: "Melaksanakan tugas secara wajar (2 Poin)", value: 2 },
        { text: "Acuh tak acuh dalam melakukan pekerjaan (1 Poin)", value: 1 },
      ],
      options6: [
        {
          text: "Selain mekakukan tugasnya, berusaha juga untuk membantu gaian / unit lain (3 Poin)",
          value: 3,
        },
        {
          text: "Dapat bekerjasama dengan bagian / unit lain secara wajar (2 Poin)",
          value: 2,
        },
        {
          text: "Tidak kooperatif dengan bagian / unit lain (1 Poin)",
          value: 1,
        },
      ],
      options7: [
        { text: "Sangat memuaskan (3 Poin)", value: 3 },
        { text: "Memuaskan (2 Poin)", value: 2 },
        { text: "Tidak memuaskan (1 Poin)", value: 1 },
      ],
      options8: [
        {
          text: "Berani memulai pembicaraan dan sering menyampaikan ide yang baik(3 Poin)",
          value: 3,
        },
        {
          text: "Berani memulai pembicaraaan dan terkadang menyampaikan ide yang baik (2 Poin)",
          value: 2,
        },
        {
          text: "Tidak ada kemauan & cenderung acuh tak acuh dengan lingkungan (1 Poin)",
          value: 1,
        },
      ],
      options9: [
        { text: "Sangat memuaskan (3 Poin)", value: 3 },
        { text: "Memuaskan (2 Poin)", value: 2 },
        { text: "Tidak memuaskan (1 Poin)", value: 1 },
      ],
      options10: [
        { text: "Sangat memuaskan (3 Poin)", value: 3 },
        { text: "Memuaskan (2 Poin)", value: 2 },
        { text: "Tidak memuaskan (1 Poin)", value: 1 },
      ],
      options11: [
        {
          text: "Menyelesaikan pekerjaan se-efisien mungkin, sehingga pekerjaan selesai lebih cepat (3 Poin)",
          value: 3,
        },
        {
          text: "Menyelesaikan pekerjaan dalam tempo yang wajar (2 Poin)",
          value: 2,
        },
        {
          text: "Menyelesaikan pekerjaan lebih lambat dari teman-teman yang tingkat pekerjaannya sama (1 Poin)",
          value: 1,
        },
      ],
      options12: [
        {
          text: "Pekerjaan dilakukan dengan teliti & berusaha mencegah hal yang mengakikabtkan kerugian (3 Poin)",
          value: 3,
        },
        {
          text: "Pekerjaan dilakukan dengan baik, terkadang terjadi sedikit kekurangan (2 Poin)",
          value: 2,
        },
        {
          text: "Pekerjaan dilakukan dengan tidak teliti dan menyebabkan kerugian (1 Poin)",
          value: 1,
        },
      ],
      options13: [
        { text: "Memenuhi SOP (3 Poin)", value: 3 },
        { text: "Dilakukan secara wajar (2 Poin)", value: 2 },
        { text: "Selalu dibawah SOP (1 Poin)", value: 1 },
      ],
      options14: [
        {
          text: "Cepat tanggap & kreatif dalam memenuhi kewajiban (3 Poin)",
          value: 3,
        },
        {
          text: "Kadang tidak memenuhi target tetapi usaha sudah dilakukan(2 Poin)",
          value: 2,
        },
        {
          text: "Selalu menghindar dari tugas & banyak alasan (1 Poin)",
          value: 1,
        },
      ],
      options15: [
        {
          text: "Antusias tanpa memperhatikan waktu untuk menyelesaikan pekerjaan (3 Poin)",
          value: 3,
        },
        {
          text: "Menerima & melaksanakan pekerjaan secara wajar (2 Poin)",
          value: 2,
        },
        {
          text: "Acuh tak acuh dalam melaksanakan pekerjaan , bekerja atas dasar terpaksa (1 Poin)",
          value: 1,
        },
      ],
      options16: [
        {
          text: "Semua pekerjaan diselesaikan sendiri tanpa perlu petunjuk & dapat dilepaskan sepenuhnya (3 Poin)",
          value: 3,
        },
        {
          text: "Umumnya pekerjaan diselesaikan sendiri, hanya beberapa hal masih memerlukan bantuan (2 Poin)",
          value: 2,
        },
        {
          text: "Pekerjaan tidak pernah dapat diselesaikan sendiri & selalu memerlukan petunjuk (1 Poin)",
          value: 1,
        },
      ],
      options17: [
        { text: "Sangat memuaskan (3 Poin)", value: 3 },
        { text: "Memuaskan (2 Poin)", value: 2 },
        { text: "Tidak memuaskan (1 Poin)", value: 1 },
      ],
      options18: [
        {
          text: "Antusias & ada kemajuan pekerjaan sering dihasilkan (3 Poin)",
          value: 3,
        },
        {
          text: "Terkadang ada usaha untuk memperbaiki cara kerja (2 Poin)",
          value: 2,
        },
        {
          text: "Tidak menunjukkan kemajuan dalam melakukan tugas (1 Poin)",
          value: 1,
        },
      ],
      options19: [
        { text: "Sangat memuaskan (3 Poin)", value: 3 },
        { text: "Memuaskan (2 Poin)", value: 2 },
        { text: "Tidak memuaskan (1 Poin)", value: 1 },
      ],
      options20: [
        { text: "Hadir lebih awal & disiplin (3 Poin)", value: 3 },
        { text: "Terkadang terlambat hadir kerja (2 Poin)", value: 2 },
        { text: "Sering ijin  karena urusan pribadi (1 Poin)", value: 1 },
      ],
      options: [
        { text: "Petugas Kebersihan", value: "Petugas Kebersihan" },
        { text: "Pengemudi", value: "Pengemudi" },
        { text: "Pesuruh", value: "Pesuruh" },
        { text: "Satpam", value: "Satpam" },
      ],
      gen: false,
      name: "",
      url: "",
    };
  },
  methods: {
    simpan() {
      let vm = this;
      for (let i = 0; i < vm.options1.length; i++) {
        if (vm.data.penilaian1 == vm.options1[i].value) {
          vm.data.isi1 = vm.options1[i].text;
        }
      }
      for (let i = 0; i < vm.options2.length; i++) {
        if (vm.data.penilaian2 == vm.options2[i].value) {
          vm.data.isi2 = vm.options2[i].text;
        }
      }
      for (let i = 0; i < vm.options3.length; i++) {
        if (vm.data.penilaian3 == vm.options3[i].value) {
          vm.data.isi3 = vm.options3[i].text;
        }
      }
      for (let i = 0; i < vm.options4.length; i++) {
        if (vm.data.penilaian4 == vm.options4[i].value) {
          vm.data.isi4 = vm.options4[i].text;
        }
      }
      for (let i = 0; i < vm.options5.length; i++) {
        if (vm.data.penilaian5 == vm.options5[i].value) {
          vm.data.isi5 = vm.options5[i].text;
        }
      }
      for (let i = 0; i < vm.options6.length; i++) {
        if (vm.data.penilaian6 == vm.options6[i].value) {
          vm.data.isi6 = vm.options6[i].text;
        }
      }
      for (let i = 0; i < vm.options7.length; i++) {
        if (vm.data.penilaian7 == vm.options7[i].value) {
          vm.data.isi7 = vm.options7[i].text;
        }
      }
      for (let i = 0; i < vm.options8.length; i++) {
        if (vm.data.penilaian8 == vm.options8[i].value) {
          vm.data.isi8 = vm.options8[i].text;
        }
      }
      for (let i = 0; i < vm.options9.length; i++) {
        if (vm.data.penilaian9 == vm.options9[i].value) {
          vm.data.isi9 = vm.options9[i].text;
        }
      }
      for (let i = 0; i < vm.options10.length; i++) {
        if (vm.data.penilaian10 == vm.options10[i].value) {
          vm.data.isi10 = vm.options10[i].text;
        }
      }
      for (let i = 0; i < vm.options11.length; i++) {
        if (vm.data.penilaian11 == vm.options11[i].value) {
          vm.data.isi11 = vm.options11[i].text;
        }
      }
      for (let i = 0; i < vm.options12.length; i++) {
        if (vm.data.penilaian12 == vm.options12[i].value) {
          vm.data.isi12 = vm.options12[i].text;
        }
      }
      for (let i = 0; i < vm.options13.length; i++) {
        if (vm.data.penilaian13 == vm.options13[i].value) {
          vm.data.isi13 = vm.options13[i].text;
        }
      }
      for (let i = 0; i < vm.options14.length; i++) {
        if (vm.data.penilaian14 == vm.options14[i].value) {
          vm.data.isi14 = vm.options14[i].text;
        }
      }
      for (let i = 0; i < vm.options15.length; i++) {
        if (vm.data.penilaian15 == vm.options15[i].value) {
          vm.data.isi15 = vm.options15[i].text;
        }
      }
      for (let i = 0; i < vm.options16.length; i++) {
        if (vm.data.penilaian16 == vm.options16[i].value) {
          vm.data.isi16 = vm.options16[i].text;
        }
      }
      for (let i = 0; i < vm.options17.length; i++) {
        if (vm.data.penilaian17 == vm.options17[i].value) {
          vm.data.isi17 = vm.options17[i].text;
        }
      }
      for (let i = 0; i < vm.options18.length; i++) {
        if (vm.data.penilaian18 == vm.options18[i].value) {
          vm.data.isi18 = vm.options18[i].text;
        }
      }
      for (let i = 0; i < vm.options19.length; i++) {
        if (vm.data.penilaian19 == vm.options19[i].value) {
          vm.data.isi19 = vm.options19[i].text;
        }
      }
      for (let i = 0; i < vm.options20.length; i++) {
        if (vm.data.penilaian20 == vm.options20[i].value) {
          vm.data.isi20 = vm.options20[i].text;
        }
      }
      for(let i =0; i < vm.optionsSetuju.length; i++){
        if(vm.data.persetujuanPenandatanganan == vm.optionsSetuju[i].value){
          vm.data.isiSetuju = vm.optionsSetuju[i].text
        }
      }
      // for(let i =0; i < vm.options.length; i++){
      //   if(vm.data.penilaian == vm.options[i].value){
      //     vm.data.isiJabatan = vm.options[i].text
      //   }
      // }
      vm.data.tanggalPenilaianPrint = moment(vm.data.tanggalPenilaian).format(
        "ll"
      );

      vm.gen = !vm.gen;
      vm.name = `Hasil Penilaian ${vm.data.namaKaryawan}.docx`;

      vm.url = this.ipBackend + "asset/docx/" + "HasilPenilaian.docx";
      console.log(vm.data, "nilai iki");
    },
  },
};
</script>